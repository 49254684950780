import React, { useState } from 'react'
import classNames from "classnames"
import { useTranslation } from 'react-i18next'

import Image from '../image'
import BlockContent from '../block-content'

const Galleries = ({ left, right, description, smallText, title, gallery, text }) => {
  const
    { t } = useTranslation('common'),
    [state, setState] = useState(0),
    [stateRight, setStateRight] = useState(0),
    [stateExperiences, setStateExperiences] = useState(0);

  const getClasses = (i) => {
    let
      classes = classNames({
        'slide-wrapper': i !== state,
        'slide-wrapper slide-current': i === state
      });
    return classes
  }

  const getClassesRight = (i) => {
    let
      classes = classNames({
        'slide-wrapper': i !== stateRight,
        'slide-wrapper slide-current': i === stateRight
      });
    return classes
  }

  const getClassesExperiences = (i) => {
    let
      classes = classNames({
        'slide-wrapper': i !== stateExperiences,
        'slide-wrapper slide-current': i === stateExperiences
      });
    return classes
  }

  const getSlide = (newState) => {
    switch (newState) {
      case 3:
        return setState(0)
      default:
        return setState(newState)
    }
  }

  const getSlideRight = (newState) => {
    switch (newState) {
      case 3:
        return setStateRight(0)
      default:
        return setStateRight(newState)
    }
  }

  const getSlideExperiences = (newState) => {
    switch (newState) {
      case 5:
        return setStateExperiences(0)
      default:
        return setStateExperiences(newState)
    }
  }

  return(
    <div className="galleries-container grid" data-scroll-section>
      <div className="description" data-scroll data-scroll-speed="1" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <BlockContent blocks={description || []}/>
      </div>
      <div className="left" data-scroll data-scroll-speed="1" data-sal='fade' data-sal-duration='750' data-sal-easing='ease' onClick={() => getSlide(state + 1)}>
        <p className="cta-click">{t("click")}</p>
        <div className="g-container">
          {left.map((slide, i) => {
            return(
              <div key={i * 100} className={getClasses(i)}>
                <Image fluid={slide.asset.fluid} alt="Gutiérrez F Studio, Event Planning"/>
              </div>
            )
          })}
        </div>
      </div>
      <div className="small" data-scroll data-scroll-speed="-1" data-sal='fade' data-sal-delay='500' data-sal-duration='750' data-sal-easing='ease'>
        <BlockContent blocks={smallText || []}/>
      </div>
      <div className="right" data-scroll data-scroll-speed="2" data-sal='fade' data-sal-duration='750' data-sal-easing='ease' onClick={() => getSlideRight(stateRight + 1)}>
        <div className="g-container">
          {right.map((slide, i) => {
            return(
              <div key={i * 750} className={getClassesRight(i)}>
                <Image fluid={slide.asset.fluid} alt="Gutiérrez F Studio, Event Planning"/>
              </div>
            )
          })}
        </div>
        <p className="cta-click">{t("click")}</p>
      </div>
      <h1 className="page-title" data-scroll data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        {title.translate}
      </h1>
      <div className="experiences-text" data-scroll data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <BlockContent blocks={text || []}/>
      </div>
      <div className="experiences-gallery" data-scroll data-scroll-speed="2" data-sal='fade' data-sal-duration='750' data-sal-easing='ease' onClick={() => getSlideExperiences(stateExperiences + 1)}>
        <div className="g-container">
          {gallery.map((slide, i) => {
            return(
              <div key={i * 950} className={getClassesExperiences(i)}>
                <Image fluid={slide.asset.fluid} alt="Gutiérrez F Studio, Event Planning"/>
              </div>
            )
          })}
        </div>
        <p className="cta-click">{t("click")}</p>
      </div>
    </div>
  )
}

export default Galleries
