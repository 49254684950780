import React from 'react'
import parse from 'html-react-parser';

const Hero = ({ title, video }) => {
  return (
    <div className="hero-container grid" data-scroll-section>
      <div className="video-container">
        {parse(video)}
      </div>
      <h2 data-scroll data-scroll-speed="1" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{title.translate}</h2>
    </div>
  )
}

export default Hero
